html,
body {
  padding: 0;
  margin: 0;
  font-family: FavoritPro, Helvetica Neue, sans-serif;
  min-height: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
}

* {
  box-sizing: border-box;
}

h2,
h3,
p {
  margin: 0;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.slick-list,
.slick-track,
.slick-slide > div,
.slick-slide > div > div {
  height: 100%;
}

.slick-dots {
  text-align: center;
  display: flex;
  justify-content: center;
  list-style: none;
}

.slick-dots li {
  display: inline-block;
}

.slick-dots li.slick-active button:before {
  color: transparent;
  content: url('/icons/slick_point_active.svg');
}

.slick-dots li button {
  background: transparent;
  border: none;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 5px;
  outline: none;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.slick-dots li button:before {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50%;
  content: url('/icons/slick_point.svg');
}

@font-face {
  font-family: 'FavoritPro';
  src: url('/fonts/FavoritPro-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FavoritPro';
  src: url('/fonts/FavoritPro-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FavoritPro';
  src: url('/fonts/FavoritPro-Bold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

.no-scroll {
  overflow: hidden;
}
